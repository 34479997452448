import { IJournalRecord } from "..";

export function CreatioCell(_: string, record: IJournalRecord) {
  const { creatio_link: creatioLink, request_id: requestId } = record;
  if (!creatioLink) {
    return null;
  }
  return (
    <a href={creatioLink} target="_blank" rel="noreferrer">
      {requestId}
    </a>
  );
}
