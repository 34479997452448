import { Col, Popover, Row, Tooltip, Form } from "antd";
import clsx from "clsx";
import { ReactElement, useState } from "react";

import Text from "core/lib/Text/Text";
import { TaskDiscriminatorEnum } from "core/app/api/enums";

import MaintenanceExecutionCheckMenu from "./MaintenanceExecutionCheckMenu";

/** Статус выполнения работы из чек-листа задания на ТО. */
export enum CheckStatusEnum {
  Unchecked = 0,
  Ok = 1,
  Broken = 2,
  Repaired = 3,
}

export interface IValue {
  id: number;
  check?: {
    description: string;
    title: string;
  };
  value: CheckStatusEnum;
  order_number?: string;
}

interface Props {
  show: boolean;
  disabled: boolean;
  value?: IValue;
  onChange?: (value: IValue) => void;
}

/** Элемент чек-листа задания на ТО: наименование работы, текущий статус, всплывающий выбор статуса выполнения работы. */
export function CheckListItem(props: Props): ReactElement {
  const { show: shouldShow, value, onChange, disabled: isDisabled } = props;
  const [isVisible, setVisible] = useState(false);
  const discriminator = Form.useWatch("discriminator");

  const isLsoTask = discriminator === TaskDiscriminatorEnum.LSO_TASK;
  // для ЛСО выделение первого пункта
  const isFirstInGroup = isLsoTask && value?.order_number === "1";

  const handleChange = (newValue: CheckStatusEnum) => {
    if (typeof onChange === "function" && value) {
      onChange({
        ...value,
        value: newValue,
      });
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      setVisible(!isVisible);
    }
  };

  if (shouldShow && value) {
    return (
      <Row key={value.id}>
        <Col span={22}>
          <div
            style={{
              marginLeft: isLsoTask && !isFirstInGroup ? "10px" : "auto",
            }}
          >
            <Tooltip title={value?.check?.description}>
              <Text
                size={isFirstInGroup ? "label1" : "label2"}
                boldness={isFirstInGroup ? "bold" : "semibold"}
                className="list-item"
              >
                {value?.check?.title}
              </Text>
            </Tooltip>
          </div>
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Col>
              <Popover
                open={isVisible}
                placement="bottom"
                content={<MaintenanceExecutionCheckMenu setVisible={setVisible} onChange={handleChange} />}
                trigger="click"
              >
                {/* marker - квадратик текущего статуса, другие классы придают цвет */}
                <div
                  onClick={handleClick}
                  className={clsx("marker", "small", {
                    disabled: isDisabled,
                    unchecked: value.value === CheckStatusEnum.Unchecked,
                    ok: value.value === CheckStatusEnum.Ok,
                    broken: value.value === CheckStatusEnum.Broken,
                    repaired: value.value === CheckStatusEnum.Repaired,
                  })}
                />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return <div />;
}
