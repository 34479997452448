import { Row, Col } from "antd";

import { IDashboardShiftStatisticTasks } from "core/shared/dashboard/hooks/interfaces";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";

import TotalByTaskType from "../TotalByTaskType";

interface TotalsByTasksTypeProps {
  isMounted?: boolean;
  statistic: IDashboardShiftStatisticTasks;
}
export function TotalsByTasksType({ isMounted, statistic }: TotalsByTasksTypeProps) {
  return (
    <Row>
      <Col span={6}>
        <TotalByTaskType
          title={isMounted ? undefined : "АВР"}
          data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)}
        />
      </Col>
      <Col span={6}>
        <TotalByTaskType
          title={isMounted ? undefined : "ЗНО"}
          data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
        />
      </Col>
      <Col span={6}>
        <TotalByTaskType
          title={isMounted ? undefined : "ТО"}
          data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
        />
      </Col>
      <Col span={6}>
        <TotalByTaskType
          title={isMounted ? undefined : "ДОП. ЗАДАЧИ"}
          data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.OTHER)}
        />
      </Col>
    </Row>
  );
}
