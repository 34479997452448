import { Drawer, Form } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { MediaGallery } from "core/widgets/TaskModals/shared/Media/MediaGallery";
import { MediaContext, useMediaContextInitialValue } from "core/shared/task/context/mediaContext";
import { useOpinTaskDraft } from "core/shared/task/hooks/useOpinTaskDraft";
import { useGetTaskAttachmentsQuery } from "core/app/api/task";

import { TableFormItem } from "./TableFormItem";

export function TaskMediaTable() {
  const { data: opinTaskDraft } = useOpinTaskDraft();
  const executionTaskId = opinTaskDraft?.execution_task_id;
  const { data } = useGetTaskAttachmentsQuery(executionTaskId ?? skipToken);
  const contextValue = useMediaContextInitialValue();
  const { setSelectedMediaId, selectedMediaId } = contextValue;

  return (
    <MediaContext.Provider value={contextValue}>
      <Form.Item name="selected_execution_task_attachments">
        <TableFormItem />
      </Form.Item>
      <Drawer
        open={!!selectedMediaId}
        onClose={() => {
          setSelectedMediaId(null);
        }}
        width="100VW"
        zIndex={2000}
      >
        {data && <MediaGallery attachments={data} />}
      </Drawer>
    </MediaContext.Provider>
  );
}
