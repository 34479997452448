import qs from "qs";

import { IResponseBody } from "core/shared/interfaces";
import { IDashboardShiftStatisticTasksByTeams } from "core/shared/dashboard/hooks/interfaces";

import { apiSlice } from "../..";
import { IEtoTask } from "../../task/eto/interfaces";
import { IRepairArea } from "../../interfaces";
import { extractResults } from "../../emptyApi";
import { IEquipmentMaintenanceJournalItem } from "../../equipment/interfaces";
import {
  getFileNameFromContentDisposition,
  handleUpdateTaskQueryStarted,
  TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
} from "../../tools";

import {
  ICreatePspaKFPlansPayload,
  IDashboardShiftStatisticTasksByTeamsPspaTask,
  IGetPspaMonthPlanSignatureProps,
  IPspaMonthPlanSignature,
  IPspaTask,
  IUpdatePspaMonthPlanSignatureProps,
  IApproveMonthPlanHistory,
  IApproveMonthPlanHistoryPayload,
  ICreateMonthPlansPayload,
  ICreateMonthPlansResponse,
  IEquipmentMaintenance,
  IMonthPlan,
  IPspaMaintenanceType,
  ICheckMonthPlanResult,
  ICheckMonthPlanPayload,
  IUnitYearMonth,
  ICopyMonthPlanPayload,
  ITransportMaintenance,
  IYearPlanTransportsStat,
  ICreateMaintenanceMountedTaskPayload,
} from "./interfaces";

export const pspaApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "pspa-kf-scheduled-task-count",
      "pspa-month-plan-signature",
      "pspa-kf-year-plans",
      "pspa-kf-month-plans",
      "pspa-kf-month-stat",
      "dashboard/shift-statistic/tasks/by-teams/pspa",
      "maintenance-types",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPspaTasks: builder.query<IResponseBody<IPspaTask>, string | undefined>({
        query: (queryProps) => `pspa-tasks/?${queryProps}`,
        providesTags: ["open-tasks"],
      }),
      getPspaWithEtoTasks: builder.query<IResponseBody<IPspaTask | IEtoTask>, string | undefined>({
        query: (queryProps) => `pspa-tasks-aggregate/?${queryProps}`,
        providesTags: ["open-tasks"],
      }),
      getPspaKFMonthPlansScheduledTaskCount: builder.query({
        query: (queryString: string) => `pspa-kf-month-plans/get_scheduled_task_count/?${queryString}`,
        providesTags: ["pspa-kf-scheduled-task-count"],
      }),
      getDashboardShiftStatisticTasksByTeamsPspa: builder.query<
        IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsPspaTask>[],
        string
      >({
        query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/pspa/?${queryString}`,
        providesTags: ["dashboard/shift-statistic/tasks/by-teams/pspa"],
      }),
      createPspaKFPlans: builder.mutation<void, ICreatePspaKFPlansPayload>({
        query(payload) {
          return {
            url: `pspa-kf-year-plans/create-plans/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat", "pspa-kf-year-plans"],
      }),
      getPspaMonthPlanSignature: builder.query<IPspaMonthPlanSignature[], IGetPspaMonthPlanSignatureProps>({
        query: (queryProps) => `pspa-month-plan-signature/?${qs.stringify(queryProps)}`,
        providesTags: ["pspa-month-plan-signature"],
      }),
      updatePspaMonthPlanSignature: builder.mutation<IPspaMonthPlanSignature[], IUpdatePspaMonthPlanSignatureProps>({
        query: ({ urlPart, body, id }) => ({
          url: `pspa-month-plan-signature/${id}/${urlPart}/`,
          method: "PATCH",
          body,
        }),
        invalidatesTags: ["pspa-month-plan-signature"],
      }),
      createMonthPlans: builder.mutation<ICreateMonthPlansResponse, ICreateMonthPlansPayload>({
        query(payload) {
          return {
            url: `month-plan/create-plans/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["month-plan", "year-plan/transports-stats"],
      }),
      getApproveMonthPlanHistory: builder.query<IApproveMonthPlanHistory[], IApproveMonthPlanHistoryPayload>({
        query: (queryProps) => `approve-month-plan/?${qs.stringify(queryProps)}`,
        providesTags: ["approve-month-plan"],
        transformResponse: extractResults,
      }),
      getEquipmentMaintenance: builder.query<
        IEquipmentMaintenance,
        {
          id: number;
          params?: {
            on_date?: string;
          };
        }
      >({
        query: ({ id, params }) => {
          const queryString = qs.stringify(params);
          return `equipments/${id}/maintenance/?${queryString}`;
        },
        providesTags: ["equipments/maintenance"],
      }),
      getEquipmentMaintenanceJournal: builder.query<IEquipmentMaintenanceJournalItem[], string>({
        query: (queryString: string) => `equipments/${queryString}/maintenance-journal/`,
        providesTags: ["equipments/maintenance-journal"],
      }),
      createMonthPlanTasks: builder.mutation({
        query: (payload) => ({
          url: `month-plan/approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: [
          "teams",
          "shifttask",
          "tasks",
          "tasks-filtered",
          "year-plan",
          "approve-month-plan",
          "month-plan/check",
        ],
      }),
      getMonthPlanPrint: builder.mutation({
        query(queryString: string) {
          return {
            url: `month-plan/print/?${queryString}`,
            method: "GET",
            responseHandler: async (response) => {
              if (response.ok) {
                const fileLink = document.createElement("a");
                fileLink.href = window.URL.createObjectURL(await response.blob());
                fileLink.target = "blank";
                const disposition = await response.headers.get("content-disposition");
                const unsafeFilename = getFileNameFromContentDisposition(disposition);
                fileLink.download = unsafeFilename || "month-plan.xls";
                fileLink.click();
              }
            },
            cache: "no-cache",
          };
        },
      }),
      getYearPlan: builder.query({
        query: (queryString: string) => `year-plan/?${queryString}`,
        providesTags: ["year-plan"],
      }),
      updateYearPlan: builder.mutation({
        query: (plan) => ({
          url: `year-plan/${plan.id}/`,
          method: "PUT",
          body: plan,
        }),
        invalidatesTags: ["year-plan"],
      }),
      getMonthPlan: builder.query<IResponseBody<IMonthPlan>, string>({
        query: (queryString: string) => `month-plan/?${queryString}`,
        providesTags: ["month-plan"],
      }),
      updateMonthPlan: builder.mutation({
        query: (plan) => ({
          url: `month-plan/${plan.id}/`,
          method: "PUT",
          body: plan,
        }),
        async onQueryStarted({ queryString, ...monthPlanUpdate }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            pspaApiSlice.util.updateQueryData("getMonthPlan", queryString, (cachedMonthPlans) => {
              const cachedMonthPlanToUpdate = cachedMonthPlans.results.find(
                (cachedMonthPlan: IMonthPlan) => cachedMonthPlan.id === monthPlanUpdate.id
              );
              if (cachedMonthPlanToUpdate) {
                Object.assign(cachedMonthPlanToUpdate, monthPlanUpdate);
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(api.util.invalidateTags(['Post']))
             */
          }
        },
        invalidatesTags: ["scheduled-task-count", "month-plan/check"],
      }),
      getMaintenanceTypes: builder.query<IPspaMaintenanceType[], string>({
        query: (queryString) => `maintenance-types/?${queryString}`,
        providesTags: ["maintenance-types"],
        transformResponse: extractResults,
      }),
      getCheckMonthPlan: builder.query<ICheckMonthPlanResult, ICheckMonthPlanPayload>({
        query: (payload) => `month-plan/check/?${qs.stringify(payload)}`,
        providesTags: ["month-plan/check"],
      }),
      monthPlanUndoApprove: builder.mutation({
        query: (payload: ICheckMonthPlanPayload) => ({
          url: `month-plan/undo_approve/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: [
          "year-plan",
          "month-plan",
          "teams",
          "shifttask",
          "tasks",
          "tasks-filtered",
          "month-plan/check",
        ],
      }),
      makeMonthPlanSchedule: builder.mutation({
        query: (payload: IUnitYearMonth) => ({
          url: `month-plan/make_schedule/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["month-plan", "scheduled-task-count"],
      }),
      copyMonthPlan: builder.mutation({
        query: (payload: ICopyMonthPlanPayload) => ({
          url: `month-plan/copy/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["month-plan", "scheduled-task-count"],
      }),
      getMonthPlanInitialPeriod: builder.query({
        query: (queryString: string) => `month-plan/get_initial_plan_period/?${queryString}`,
        providesTags: ["month-plan/get_initial_plan_period"],
      }),
      getTransportMaintenance: builder.query<ITransportMaintenance, { id: number; params?: { on_date?: string } }>({
        query: ({ id, params }) => {
          const queryString = qs.stringify(params);
          return `transport/${id}/maintenance/?${queryString}`;
        },
        providesTags: ["transport/maintenance"],
      }),
      getRepairAreas: builder.query<IRepairArea[], string>({
        query: (queryString) => `repair-areas/?${queryString}`,
        providesTags: ["repair-areas"],
        transformResponse: extractResults,
      }),
      getYearPlanTransportsStats: builder.query<IYearPlanTransportsStat[], string>({
        query: (queryString: string) => `year-plan/transports-stats/?${queryString}`,
        providesTags: ["year-plan/transports-stats"],
      }),
      makeStationaryEquipmentSchedule: builder.mutation({
        query: (payload) => ({
          url: `month-plan/make_stationary_equipment_schedule/`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["month-plan"],
      }),
      createMaintenanceMountedTask: builder.mutation<string, ICreateMaintenanceMountedTaskPayload>({
        query(payload: ICreateMaintenanceMountedTaskPayload) {
          return {
            url: "tasks/create-maintenance-mounted-task/",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["tasks", "kanban-board", "shifttask"],
      }),
      updatePspaTask: builder.mutation({
        query: ({ rtkQueryCacheKey, ...task }) => ({
          url: `pspa-tasks/${task.id}/`,
          method: "PATCH",
          body: task,
        }),
        async onQueryStarted(args, queryContext) {
          await handleUpdateTaskQueryStarted(args, queryContext);
        },
        invalidatesTags: (result, error, arg) => [
          { type: "task", id: arg.id },
          ...TAGS_TO_BE_INVALIDATED_ON_TASK_UPDATE,
        ],
      }),
    }),
  });

export const {
  useGetPspaKFMonthPlansScheduledTaskCountQuery,
  useGetDashboardShiftStatisticTasksByTeamsPspaQuery,
  useCreatePspaKFPlansMutation,
  useGetPspaMonthPlanSignatureQuery,
  useUpdatePspaMonthPlanSignatureMutation,
  useGetPspaTasksQuery,
  useGetPspaWithEtoTasksQuery,
  useCreateMonthPlansMutation,
  useGetApproveMonthPlanHistoryQuery,
  useGetEquipmentMaintenanceQuery,
  useGetEquipmentMaintenanceJournalQuery,
  useCreateMonthPlanTasksMutation,
  useGetMonthPlanPrintMutation,
  useGetYearPlanQuery,
  useGetMonthPlanQuery,
  useUpdateMonthPlanMutation,
  useUpdateYearPlanMutation,
  useGetMaintenanceTypesQuery,
  useGetCheckMonthPlanQuery,
  useMonthPlanUndoApproveMutation,
  useMakeMonthPlanScheduleMutation,
  useCopyMonthPlanMutation,
  useGetMonthPlanInitialPeriodQuery,
  useGetTransportMaintenanceQuery,
  useGetRepairAreasQuery,
  useCreateMaintenanceMountedTaskMutation,
  useGetYearPlanTransportsStatsQuery,
  useMakeStationaryEquipmentScheduleMutation,
  useUpdatePspaTaskMutation,
} = pspaApiSlice;
