import { Row, Col, Form } from "antd";

import useUiPermissions from "core/shared/permissions/hooks/useUiPermissions";
import { RadioGroup } from "core/shared/components";

import { useRequiredCustomerApproval } from "../hooks";

import DateSelect from "./DateSelect";
import ShiftSelect from "./ShiftSelect";
import TeamSelect from "./TeamSelect";

import "./styles.scss";

interface Props {
  showSelector?: boolean;
}

function ShiftSelector({ showSelector = true }: Props) {
  const target = Form.useWatch("taskListOrshift");
  const requiredCustomerApproval = useRequiredCustomerApproval();
  const { CAN_SELECT_SHIFT_AND_DATE } = useUiPermissions("create_task_modal/any");

  return (
    <>
      <Form.Item name="taskListOrshift" hidden={!showSelector}>
        <RadioGroup
          items={[
            {
              value: "taskList",
              label: "Добавить в список задач",
            },
            {
              value: "shift",
              label: "Добавить в смену",
              disabled: requiredCustomerApproval || !CAN_SELECT_SHIFT_AND_DATE,
            },
          ]}
        />
      </Form.Item>
      {target === "shift" && (
        <Row gutter={16} className="mb-2">
          {CAN_SELECT_SHIFT_AND_DATE && (
            <>
              <Col span={8}>
                <ShiftSelect />
              </Col>
              <Col span={8}>
                <DateSelect />
              </Col>
            </>
          )}
          <Col span={CAN_SELECT_SHIFT_AND_DATE ? 8 : 24}>
            <TeamSelect required={!CAN_SELECT_SHIFT_AND_DATE} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default ShiftSelector;
