import { Row, Col } from "antd";
import { ReactElement } from "react";
import { get, groupBy } from "lodash";

import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import { ITask } from "core/app/api/task/interfaces";
import { Customer, Unit, Location, AccumulatorElapsedTime } from "core/widgets/TaskModals/shared/Fields";
import LocationSelect from "core/widgets/TaskModals/shared/Mounted/LocationSelect";
import RepairAreaSelect from "core/widgets/TaskModals/shared/Mounted/RepairAreaSelect";
import { Deadline } from "core/widgets/TaskModals/shared/Fields/Deadline";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО для навесного оборудования ПСПА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;
  const { checklist } = task;
  const checklistsByEquipmentType = groupBy(checklist, "check.equipment_type.name");
  const systems = Object.keys(checklistsByEquipmentType)
    .filter((item) => item !== "undefined")
    .join(", ");

  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={12}>
          <LocationSelect />
        </Col>
        <Col span={12}>
          <RepairAreaSelect />
        </Col>
      </Row>
      <Row gutter={16} className="mb-1">
        <Col span={11}>
          <Deadline task={task} />
          <Customer task={task} />
          <Unit task={task} />
          <Location task={task} />
          <AccumulatorElapsedTime task={task} />
        </Col>
        <Col span={13}>
          {/* TODO: Типизировать СГПС задачу, убрать get */}
          <Field label="Тип ТО" value={get(task, "maintenance_type.name", "")} />
          <Field label="Системы" value={systems} />
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
