import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";
import { IDashboardShiftStatisticTasksByType } from "core/shared/dashboard/hooks/interfaces";

import ProgressBar from "./ProgressBar";

interface ISystemStatRowProps {
  isMounted?: boolean;
  name: string | ReactElement;
  data?: IDashboardShiftStatisticTasksByType[];
  nameIsLong?: boolean;
}

export default function SystemStatRow({
  name,
  data,
  isMounted,
  nameIsLong = false,
}: ISystemStatRowProps): ReactElement {
  const color = isMounted ? "#32C5FF" : "#7ED321";
  const isEmpty = !data?.some(
    (item) => item.closed_sheduled_tasks || item.closed_unsheduled_tasks || item.sheduled_tasks || item.unsheduled_tasks
  );

  if (isEmpty) {
    return <div />;
  }
  return (
    <Row gutter={24} className="mt-1">
      {!isMounted && (
        <>
          <Col span={nameIsLong ? 5 : 3} style={{ alignSelf: "center", lineHeight: 1 }}>
            <Text boldness="semibold" size="label3">
              {name || "НЕ УКАЗАНО"}
            </Text>
          </Col>
          <Col span={nameIsLong ? 19 : 21}>
            <Row>
              <Col span={6}>
                <ProgressBar data={data?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)} strokeColor={color} />
              </Col>
              <Col span={6}>
                <ProgressBar
                  data={data?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
                  strokeColor={color}
                />
              </Col>
              <Col span={6}>
                <ProgressBar
                  data={data?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
                  strokeColor={color}
                />
              </Col>
              <Col span={6}>
                <ProgressBar data={data?.find((item) => item.name === TaskTypesIdsEnum.OTHER)} strokeColor={color} />
              </Col>
            </Row>
          </Col>
        </>
      )}
      {isMounted && (
        <>
          <Col span={4} style={{ alignSelf: "center", lineHeight: 1 }}>
            <Text boldness="semibold" size="label3">
              {name || "НЕ УКАЗАНО"}
            </Text>
          </Col>

          <Col span={10}>
            <ProgressBar data={data?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)} strokeColor={color} />
          </Col>
          <Col span={10}>
            <ProgressBar
              data={data?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
              strokeColor={color}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
