import { ReactElement } from "react";
import { Row, Col, Empty, Spin } from "antd";

import { safePercent } from "core/shared/tools/safePercent";
import { useDashboardShiftStatisticTasksByCustomerDepartmentsVols } from "core/shared/dashboard/hooks/useDashboardShiftStatisticTasksByObjectsVols";

import SystemStatRow from "../shared/SystemStatRow";
import { Total } from "../shared/Total";
import { TotalsByTasksType } from "../shared/TotalsByTaskTypes";

import { VolsDepartmentName } from "./VolsDepartmentName";

export default function ByCustomDepartmentsVols(): ReactElement {
  const { data, isError, isFetching } = useDashboardShiftStatisticTasksByCustomerDepartmentsVols();

  if (isFetching) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  if (!data || isError) {
    return <Empty />;
  }

  return (
    <Row gutter={16}>
      <Col span={3}>
        <Total
          showTitle
          fact={safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
          plan={100 - safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
        />
      </Col>
      <Col span={21}>
        <Row gutter={24}>
          <Col span={5} style={{ fontSize: "16px", fontWeight: "bold", paddingTop: "8px" }}>
            Подразделения
          </Col>
          <Col span={19}>
            <TotalsByTasksType statistic={data} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {data.by_customer_department?.map((object) => (
              <div key={object.customer_department.id}>
                <SystemStatRow
                  name={<VolsDepartmentName name={object.customer_department.name} />}
                  nameIsLong
                  data={object.by_type}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
