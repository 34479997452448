import { Button, Col, Form, FormInstance, FormProps, Row, message } from "antd";
import { Store } from "antd/lib/form/interface";
import { PropsWithChildren, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { setCreateTaskModalType } from "core/app/store/temp-slice";
import Text from "core/lib/Text/Text";
import { TaskTypeTitleEnum } from "core/widgets/CreateTaskModal";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import { ICreateTaskError } from "core/widgets/CreateTaskModal/interfaces";

import TypeSwitchField from "../../Fields/TypeSwitchField";
import "./styles.scss";

function isCreateTaskError(error: unknown): error is ICreateTaskError {
  return (error as ICreateTaskError).data?.non_field_errors !== undefined;
}

interface CreateTaskBaseFormProps<TInitialValues extends Store, TCreateTaskResponse> extends PropsWithChildren {
  createTaskFunction: (values: TInitialValues) => TCreateTaskResponse;
  initialValues: TInitialValues;
  createTaskIsLoading: boolean;
  /* Условие, при котором запрос на создание задачи будет отменён без вывода ошибки */
  shouldCancelSubmit?: boolean;
  formProps?: FormProps<TInitialValues>;
  /* Наличие свитча типов оборудования (навесное/стационарное) */
  canSwitchTypes?: boolean;
  externalForm?: FormInstance<TInitialValues>;
}

export function CreateTaskBaseForm<TCreateTaskResponse, TInitialValues extends Store>(
  props: CreateTaskBaseFormProps<TInitialValues, TCreateTaskResponse>
) {
  const {
    createTaskFunction: createTask,
    initialValues,
    createTaskIsLoading,
    shouldCancelSubmit,
    formProps,
    canSwitchTypes = false,
    externalForm = undefined,
    children,
  } = props;
  const dispatch = useAppDispatch();
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [form] = Form.useForm(externalForm);

  const handleClose = () => {
    dispatch(setCreateTaskModalType(null));
  };

  const handleSubmit = async (values: typeof initialValues) => {
    if (!createTaskModalType || shouldCancelSubmit) {
      return;
    }
    setShowErrorMessage(false);

    try {
      await createTask(values);
      message.success("Заявка создана успешно.");
      handleClose();
    } catch (e) {
      if (isCreateTaskError(e)) {
        e.data.non_field_errors?.forEach((error) => {
          message.error(error);
        });
      } else {
        message.error("При создании заявки произошла ошибка.");
      }
    }
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  useEffect(() => {
    form.resetFields();
    setShowErrorMessage(false);
  }, [createTaskModalType, form]);

  return (
    <Form
      className="create-task-modal__form"
      form={form}
      layout="vertical"
      name="custom-task"
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleValidationFailed}
      {...formProps}
    >
      <Row gutter={24}>
        <Col className="mb-3" span={24}>
          {createTaskModalType && (
            <Text boldness="black" size="h4" className="uppercase">
              Создание {TaskTypeTitleEnum[createTaskModalType]}
            </Text>
          )}
        </Col>
        {canSwitchTypes && (
          <Col className="mb-1" span={24}>
            <TypeSwitchField />
          </Col>
        )}
        {showErrorMessage && (
          <Col className="mb-3" span={24}>
            <ErrorMessage />
          </Col>
        )}
        {children}
      </Row>
      <Row gutter={32} justify="center" className="mt-4">
        <Col span={12}>
          <Button className="w-100 modal-button" size="large" onClick={handleClose}>
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="w-100 modal-button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={createTaskIsLoading}
          >
            СОЗДАТЬ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
