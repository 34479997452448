import { useTaskId } from "core/shared/task/hooks/useTaskId";
import { Text } from "core/lib";

import { IJournalRecord } from "..";

interface CellProps {
  record: IJournalRecord;
}

export default function NumberCell({ record }: CellProps) {
  const [, setTaskId] = useTaskId();
  return (
    <Text
      size="label2"
      onClick={() => {
        setTaskId(record.key);
      }}
      className="blue"
      style={{
        cursor: "pointer",
      }}
    >
      {record.number}
    </Text>
  );
}
