import { ReactElement } from "react";
import { Row, Col, Empty, Spin } from "antd";

import { safePercent } from "core/shared/tools/safePercent";
import { useDashboardShiftStatisticTasksByObjectsOossa } from "core/shared/dashboard/hooks/useDashboardShiftStatisticTasksByObjectsOossa";

import SystemStatRow from "../shared/SystemStatRow";
import { Total } from "../shared/Total";
import { TotalsByTasksType } from "../shared/TotalsByTaskTypes";

import { ObjectName } from "./ObjectName";

function ByObjectsOossa(): ReactElement {
  const { data, isError, isFetching } = useDashboardShiftStatisticTasksByObjectsOossa();

  if (isFetching) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  if (!data || isError) {
    return <Empty />;
  }

  return (
    <Row gutter={16}>
      <Col span={3}>
        <Total
          showTitle
          fact={safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
          plan={100 - safePercent(data.closed_sheduled_tasks, data.sheduled_tasks)}
        />
      </Col>
      <Col span={21}>
        <Row gutter={24}>
          <Col span={5} />
          <Col span={19}>
            <TotalsByTasksType statistic={data} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {data.by_object?.map((object) => (
              <div key={object.object.object_id}>
                <SystemStatRow name={<ObjectName object={object.object} />} nameIsLong data={object.by_type} />
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export { ByObjectsOossa };
