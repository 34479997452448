import { ReactElement } from "react";
import { Table, Card, Spin } from "antd";

import Text from "core/lib/Text/Text";
import { taskTypes } from "core/app/store/task-slice";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";
import { TaskModal } from "core/widgets/TaskModals";
import { useGetEquipmentMaintenanceJournalQuery } from "core/app/api/units/PSPA";

import NumberCell from "./NumberCell";
import { CreatioCell } from "./CreatioCell";

export interface IJournalRecord {
  key: number;
  number: string | null;
  datePlan: string | false;
  dateFact: string | false;
  type: string | undefined;
  fio: string | undefined;
  state: string;
  request_id: string | null;
  creatio_link?: string;
}

const columns = [
  {
    title: "№ ИНЦИДЕНТА",
    render: CreatioCell,
  },
  {
    title: "№ ЗАДАЧИ",
    dataIndex: "number",
    render: (_: string, record: IJournalRecord) => <NumberCell record={record} />,
  },
  {
    title: "ДАТА ПЛАН",
    dataIndex: "datePlan",
  },
  {
    title: "ДАТА ФАКТ",
    dataIndex: "dateFact",
  },
  {
    title: "ТИП",
    dataIndex: "type",
  },
  {
    title: "ФИО ОТВЕТСТВЕННОГО",
    dataIndex: "fio",
  },
  {
    title: "СТАТУС",
    dataIndex: "state",
  },
];

interface MaintenanceTableProps {
  equipmentId: string | number;
}

function MaintenanceTable({ equipmentId }: MaintenanceTableProps): ReactElement {
  const { data, isLoading, isSuccess } = useGetEquipmentMaintenanceJournalQuery(`${equipmentId}`);

  const normalizedData: IJournalRecord[] | undefined = data?.map((item) => ({
    key: item.task_id,
    number: item.number,
    // item.type === TaskTypesIds.RECOVERY || item.type === TaskTypesIds.SERVICE_REQUEST ? item.request_id : item.number,
    datePlan: dayjs(item.planned_date).isValid() && dayjs(item.planned_date).format(displayDateFormat),
    dateFact: dayjs(item.closing_date).isValid() && dayjs(item.closing_date).format(displayDateFormat),
    type: taskTypes.find((taskType) => taskType.id === item.type)?.titleSuperShort,
    fio: item.executor?.full_name,
    state: item.state.name,
    request_id: item.request_id,
    creatio_link: item.creatio_link,
  }));

  return (
    <>
      <div className="mt-4">
        <Text boldness="semibold" className="uppercase">
          Журнал обслуживания
        </Text>
      </div>
      <Card bordered={false}>
        {isLoading && (
          <div className="spinner-container">
            <Spin />
          </div>
        )}
        {isSuccess && <Table size="small" dataSource={normalizedData} columns={columns} />}
      </Card>
      <TaskModal />
    </>
  );
}

export default MaintenanceTable;
