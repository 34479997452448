/** @jsxImportSource @emotion/react */
import { Button, Row, Col, Space } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { ITask } from "core/app/api/task/interfaces";
import { Customer, Unit, Location, AccumulatorElapsedTime } from "core/widgets/TaskModals/shared/Fields";
import { useNetworkState } from "core/shared/main/hooks/useNetworkState";
import LocationSelect from "core/widgets/TaskModals/shared/Mounted/LocationSelect";
import RepairAreaSelect from "core/widgets/TaskModals/shared/Mounted/RepairAreaSelect";
import { OpinActions } from "core/widgets/TaskModals/shared/OpinActions";
import { useGetActMutation } from "core/app/api";
import { StateNamesEnum } from "core/app/api/task/enums";
import { Deadline } from "core/widgets/TaskModals/shared/Fields/Deadline";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО для навесного оборудования ПСПА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;
  const [getActRequest, getActResult] = useGetActMutation();
  const { isOnline } = useNetworkState();

  const handlePrintClick = () => {
    getActRequest(task.id);
  };

  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={12}>
          <LocationSelect />
        </Col>
        <Col span={12}>
          <RepairAreaSelect />
        </Col>
      </Row>
      <Row gutter={16} className="mb-1">
        <Col span={11}>
          <Deadline task={task} />
          <Customer task={task} />
          <Unit task={task} />
          <Location task={task} />
          <AccumulatorElapsedTime task={task} />
        </Col>
        <Col span={13}>
          <Space direction="vertical" css={{ width: "100%" }} size="large">
            <Row justify="end">
              <Col>
                {task?.state?.name === StateNamesEnum.Closed && (
                  <Button disabled={!isOnline} onClick={handlePrintClick} loading={getActResult.isLoading}>
                    ПЕЧАТЬ АКТА
                  </Button>
                )}
              </Col>
            </Row>
            <OpinActions />
            <Space>
              <Text size="label3">Системы:</Text>
              {task.maintenance_execution?.map((item) => (
                <Text size="label1" boldness="black" key={item.id}>
                  {item.equipment_type.name}
                </Text>
              ))}
            </Space>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
