import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { formattedSafePercent } from "core/shared/tools/safePercent";

export interface StatProps {
  data?: {
    sheduled_tasks: number;
    unsheduled_tasks: number;
    closed_sheduled_tasks: number;
    closed_unsheduled_tasks: number;
  };
}

function Stat({ data }: StatProps): ReactElement {
  let total = 0;
  let leftCol = {
    plan: 0,
    fact: 0,
    left: 0,
  };
  let rightCol = {
    ...leftCol,
  };

  if (data) {
    total = data.sheduled_tasks + data.unsheduled_tasks;
    leftCol = {
      plan: data.sheduled_tasks,
      fact: data.closed_sheduled_tasks,
      left: data.sheduled_tasks - data.closed_sheduled_tasks,
    };
    rightCol = {
      plan: data.unsheduled_tasks,
      fact: data.closed_unsheduled_tasks,
      left: data.unsheduled_tasks - data.closed_unsheduled_tasks,
    };
  }

  return (
    <Row justify="center">
      <Col span={9}>
        <Row justify="end">
          <Col>
            <div
              style={{
                textAlign: "end",
                lineHeight: "1.3",
              }}
            >
              <Text boldness="regular" size="label3">
                план
              </Text>{" "}
              <Text boldness="black" size="label3">
                {leftCol.plan}
              </Text>
              <br />
              <Text boldness="regular" size="label3">
                факт
              </Text>{" "}
              <Text boldness="black" size="label3" style={{ color: "#6DD400" }}>
                {" "}
                {leftCol.fact} {formattedSafePercent(leftCol.fact, leftCol.plan)}
              </Text>
              <br />
              <Text boldness="regular" size="label3">
                остаток
              </Text>{" "}
              <Text boldness="black" size="label3" style={{ color: "#FA6400" }}>
                {leftCol.left} {formattedSafePercent(leftCol.left, leftCol.plan)}
              </Text>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Row justify="center">
          <Col>
            <Text boldness="black" size="h4">
              {total}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={9}>
        <Row justify="start">
          <Col>
            <div style={{ lineHeight: "1.3" }}>
              <Text boldness="black" size="label3">
                {rightCol.plan}
              </Text>{" "}
              <Text boldness="regular" size="label3">
                вне плана
              </Text>
              <br />
              <Text boldness="black" size="label3" style={{ color: "#6DD400" }}>
                {rightCol.fact} {formattedSafePercent(rightCol.fact, rightCol.plan)}
              </Text>{" "}
              <Text boldness="regular" size="label3">
                факт
              </Text>
              <br />
              <Text boldness="black" size="label3" style={{ color: "#FA6400" }}>
                {rightCol.left} {formattedSafePercent(rightCol.left, rightCol.plan)}
              </Text>{" "}
              <Text boldness="regular" size="label3">
                остаток
              </Text>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Stat;
