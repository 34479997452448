import { ReactElement, useContext } from "react";
import { Row, Col, Divider } from "antd";

import icShowel from "core/images/ic-showel.svg";
import icHammer from "core/images/ic-hammer.svg";
import icAxes from "core/images/ic-axes.svg";
import icWheelBarrow from "core/images/ic-wheelbarrow.svg";
import { useGetDashboardShiftStatisticTasksQuery } from "core/app/api/dashboard";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { TaskTypesIdsEnum } from "core/app/api/task/enums";

import Stat, { StatProps } from "./Stat";

interface StatColProps {
  title: string;
  iconSrc?: string;
  data: StatProps["data"];
}

function StatCol({ title, iconSrc, data }: StatColProps): ReactElement {
  return (
    <Col flex={20}>
      <Row justify="center" gutter={8}>
        {iconSrc && (
          <Col>
            <img width="24px" height="24px" src={iconSrc} alt="" />
          </Col>
        )}
        <Col>{title}</Col>
      </Row>
      <Stat data={data} />
    </Col>
  );
}

function Header(): ReactElement {
  const { queryString, teamId } = useContext(ShiftContext);

  const {
    data: dashboardShiftStatisticTasks,
    isSuccess,
    isFetching,
  } = useGetDashboardShiftStatisticTasksQuery(queryString, {
    skip: !queryString,
  });

  let statistic = dashboardShiftStatisticTasks;
  if (teamId) {
    statistic = dashboardShiftStatisticTasks?.by_team?.find((item) => item.id === teamId);
  }

  const statColsData: StatColProps[] = [
    { title: "ИТОГО", data: statistic },
    {
      title: "АВР",
      data: statistic?.by_type?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY),
      iconSrc: icAxes,
    },
    {
      title: "ЗНО",
      data: statistic?.by_type?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST),
      iconSrc: icShowel,
    },
    {
      title: "ТО",
      data: statistic?.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE),
      iconSrc: icHammer,
    },
    {
      title: "ДОП. ЗАДАЧИ",
      data: statistic?.by_type?.find((item) => item.name === TaskTypesIdsEnum.OTHER),
      iconSrc: icWheelBarrow,
    },
  ];

  return (
    <div>
      {!isFetching && isSuccess && statistic && (
        <Row>
          {statColsData.map((item, index) => (
            <>
              <StatCol key={item.title} {...item} />
              {index + 1 < statColsData.length && (
                <Col flex={1}>
                  <Divider type="vertical" className="h-100" />
                </Col>
              )}
            </>
          ))}
        </Row>
      )}
    </div>
  );
}

export default Header;
