import { skipToken } from "@reduxjs/toolkit/query/react";

import { useGetWorkRegimesQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";

export const useUnitShifts = () => {
  const [unitId] = useUnitId();
  return useGetWorkRegimesQuery(unitId ?? skipToken);
};

export const useGetShiftByNumber = (shiftNumber?: 1 | 2 | 3 | null) => {
  const { data } = useUnitShifts();
  return data?.find((item) => item.number === shiftNumber);
};

export const useShiftName = (shiftNumber?: 1 | 2 | 3 | null) => {
  const shift = useGetShiftByNumber(shiftNumber);
  return shift?.name || String(shiftNumber);
};
