import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ForwardedRef, forwardRef } from "react";
import { Carousel } from "react-responsive-carousel";

import { useMediaContext } from "core/shared/task/context/mediaContext";
import { IAttachment } from "core/app/api/task/interfaces";

import MediaGalleryItem from "./MediaGalleryItem";

import "./styles.scss";

interface MediaGalleryProps {
  attachments: IAttachment[];
}

/** Список медиа-файлов (карусель). */
const MediaGallery = forwardRef(({ attachments }: MediaGalleryProps, ref: ForwardedRef<Carousel>) => {
  const { selectedMediaId } = useMediaContext();
  const selectedItem = attachments.findIndex((item) => item.id === selectedMediaId);
  return (
    <Carousel
      className="task-media-gallery"
      swipeable
      swipeScrollTolerance={5}
      emulateTouch
      showStatus
      showThumbs={false}
      selectedItem={selectedItem}
      autoFocus
      useKeyboardArrows
      ref={ref}
    >
      {attachments.map((attachment) => (
        <MediaGalleryItem key={attachment.id} attachment={attachment} />
      ))}
    </Carousel>
  );
});

export { MediaGallery };
