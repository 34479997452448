export enum MonthStatusEnum {
  NOW = "NOW",
  BEFORE = "BEFORE",
  AFTER = "AFTER",
}

export enum ShiftSlugsEnum {
  DAY = "DAY",
  EVENING = "EVENING",
  NIGHT = "NIGHT",
  DAY_2 = "DAY_2",
  NIGHT_2 = "NIGHT_2",
}

/**
 * Роли пользователя
 */
export enum RoleEnum {
  /** Начальник цеха */
  WORKSHOP_LEAD = "workshop_lead",
  /** Начальник цеха ПСПА */
  PSPA_WORKSHOP_LEAD = "pspa_workshop_lead",
  /** Начальник цеха СЛПИ */
  SLPI_WORKSHOP_LEAD = "slpi_workshop_lead", // надеюсь, это в будущем разделится на две отдельные роли
  /** Руководитель службы */
  SERVICE_LEAD = "service_lead",
  /** Руководитель ПСПА */
  PSPA_SERVICE_LEAD = "pspa_service_lead",
  /** Руководитель СЛПИ */
  SLPI_SERVICE_LEAD = "slpi_service_lead",
  /** Представитель заказчика */
  CUSTOMER_REPRESENTATIVE = "customer_representative",
  /** Представитель заказчика с возможностью подписи */
  CUSTOMER_REPRESENTATIVE_SIGNATORY = "customer_representative_signatory",
  /** Старший электромеханик */
  SHIFT_LEAD = "shift_lead",
  /** Начальник участка */
  UNIT_LEAD = "unit_lead",
  /** Бригадир (производитель работ) */
  TEAM_LEAD = "team_lead",
  /** Машинист */
  DRIVER = "driver",
  /** Надёжник */
  RELIABILITY_ENGINEER = "reliability_engineer",
  /** Редактор справочников */
  CATALOG_ADMIN = "catalog_admin",
}

export enum EtoColumnIdEnum {
  VEHICLE = "vehicle",
  STATUS = "status",
  NEXT = "next",
  ENGINE_HOURS = "engine_hours",
  UNIT = "UNIT",
  SHIFTS = "shifts",
}
